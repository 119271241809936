import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/meddbtech.github.io/meddbtech.github.io/node_modules/gatsby-theme-catalyst-core/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "about-us"
    }}>{`About us`}</h2>
    <p>{`Meddbtech is a software company that focusing on web and mobile application enterprise.
We provide a solution to customs in many industries, e.g. heath tech, education tech.`}</p>
    <h3 {...{
      "id": "vision"
    }}>{`Vision`}</h3>
    <p>{`To be a leading software company for creating enterprise application and provide them with cost-effective,
easy-to-use and secure solutions which are holistic and relevant to their needs.`}</p>
    <h3 {...{
      "id": "mission"
    }}>{`Mission`}</h3>
    <ul>
      <li parentName="ul">{`Create a high quality software and maintainable`}</li>
      <li parentName="ul">{`Iterate delivery to meet a customer need`}</li>
      <li parentName="ul">{`Update to date with a new technology to improve the workflow`}</li>
      <li parentName="ul">{`Work life balance and enjoy working  `}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      